<template>
  <div class="py-6 space-y-6" v-if="paymentMethodsCount >= 2">
    <div>Forma de pagamento</div>
    <ul class="flex justify-center gap-6 w-full">
      <li class="flex items-center">
        <input class="hidden peer" name="credit-card" type="radio" id="credit-card" value="credit_card" v-model="paymentMethod"/>
        <label
          class="btn outline font-normal md:w-64"
          :for="`credit-card`"
        >
          <i class="far fa-credit-card"></i>
          Cartão de crédito
        </label>
      </li>

      <li class="flex items-center">
        <input class="hidden peer" name="boleto" type="radio" id="boleto" value="boleto" v-model="paymentMethod"/>
        <label
          class="btn outline font-normal md:w-64"
          :for="`boleto`"
        >
          <i class="fas fa-barcode"></i>
          Boleto
        </label>
      </li>
    </ul>
  </div>
  <div class="space-y-6" v-if="paymentMethod === 'credit_card'">

    <div class="space-y-6 md:space-y-0 md:flex md:space-x-6">
      <base-input
        label="Número do cartão"
        name="cardNumber"
        type="text"
        v-model="cardNumber"
        placeholder="1234 1234 1234 1234"
        :right-icon="brandClass"
        :mask="cardNumberMask"
        :autofocus="true"
        :has-error="!!cardNumberErrorMessage"
        :error-message="cardNumberErrorMessage"
      />

      <base-input
        label="Nome impresso no cartão"
        name="cardHolderName"
        type="text"
        v-model="cardHolderName"
        :has-error="!!cardHolderNameErrorMessage"
        :error-message="cardHolderNameErrorMessage"
      />
    </div>

    <div class="flex space-x-6">
      <base-input
        label="Data de validade"
        name="cardExpirationDate"
        type="text"
        v-model="cardExpirationDate"
        placeholder="MM/AA"
        mask="##/##"
        :has-error="!!cardExpirationDateErrorMessage"
        :error-message="cardExpirationDateErrorMessage"
      />
   
      <base-input
        label="Código de segurança"
        name="cardCVV"
        type="text"
        v-model="cardCVV"
        placeholder="CVC"
        :mask="cvvMask"
        :has-error="!!cardCVVErrorMessage"
        :error-message="cardCVVErrorMessage"
      />
    </div>

    <installment-options class="md:hidden" v-if="!useMonthlyValue" />

    <coupon-options class="md:hidden" />

    <total-payable />
  </div>
  <div class="space-y-6" v-if="paymentMethod === 'boleto'">
    <div>
      Você escolheu pagar com boleto. Seu acesso será liberado assim que a instituição de pagamento nos informar sobre o pagamento do boleto.
    </div>
    <div class="w-full">
      <div class="font-bold text-lg mb-2 text-center lg:text-left">Atenção a estes detalhes:</div>
      <ul class="ml-4">
        <li>Pagamento via boleto apenas à vista;</li>
        <li>O pagamento pode levar até 3 dias úteis para sere compensado;</li>
        <li>Fique atento(a) à data de vencimento;</li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useCheckout } from '@/composables/checkout/useCheckout'
import InstallmentOptions from '@/components/pages/checkout/InstallmentOptions.vue'
import CouponOptions from '@/components/pages/checkout/CouponOptions.vue'
import creditCardType from 'credit-card-type'
import { helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import TotalPayable from './TotalPayable.vue'

export default defineComponent({
  components: {
    BaseInput,
    InstallmentOptions,
    CouponOptions,
    TotalPayable
},

  setup () {
    const { t } = useI18n()

    const {
      checkoutFormData,
      paymentMethods,
      updateCheckoutFormData,
      useMonthlyValue
    } = useCheckout()

    const paymentMethod = computed({
      get: () => checkoutFormData.value.paymentMethod,
      set: (value) => updateCheckoutFormData({paymentMethod: value})
    })

    const cardNumber = computed({
      get: () => checkoutFormData.value.cardNumber,
      set: (value) => updateCheckoutFormData({cardNumber: value})
    })

    const cardHolderName = computed({
      get: () => checkoutFormData.value.cardHolderName,
      set: (value) => updateCheckoutFormData({cardHolderName: value})
    })

    const cardExpirationDate = computed({
      get: () => checkoutFormData.value.cardExpirationDate,
      set: (value) => updateCheckoutFormData({cardExpirationDate: value})
    })

    const cardCVV = computed({
      get: () => checkoutFormData.value.cardCVV,
      set: (value) => updateCheckoutFormData({cardCVV: value})
    })

    const creditCardTypeInfo = computed(() => {
      const creditCardInfo = creditCardType(cardNumber.value)
      return creditCardInfo.length ? creditCardInfo[0] : null
    })

    const cardNumberMask = computed(() => {
      if (creditCardTypeInfo.value) {
        const card = creditCardTypeInfo.value
        const cardNumberLength = card.lengths[0]

        const offsets = [0, ...card.gaps, cardNumberLength]
        const components = []

        let currentCardNumber = ''
        for (let i = 0; i <= cardNumberLength; i++) {
          currentCardNumber = currentCardNumber + '1'
        }

        for (let i = 0; offsets[i] < cardNumberLength; i++) {
          const start = offsets[i];
          const end = Math.min(offsets[i + 1], cardNumberLength);
          components.push(currentCardNumber.substring(start, end));
        }

        return components.join(" ").replace(/\d/g, '#')
      }

      return '#### #### #### ####'
    })

    const cvvMask = computed(() => {
      const mask = '####'
      const cvvSize = creditCardTypeInfo.value ? creditCardTypeInfo.value.code.size : 3

      return mask.substring(0, cvvSize)
    })

    const brandClass = computed(() => {
      const cardBrandToClass: {[key: string]: string} = {
        'visa': 'fab fa-cc-visa',
        'mastercard': 'fab fa-cc-mastercard',
        'american-express': 'fab fa-cc-amex',
        'discover': 'fab fa-cc-discover',
        'diners-club': 'fab fa-cc-diners-club',
        'jcb': 'fab fa-cc-jcb',
        'unionpay': 'far fa-credit-card',
        'maestro': 'far fa-credit-card',
        'mir': 'far fa-credit-card',
        'elo': 'far fa-credit-card',
        'hiper': 'far fa-credit-card',
        'hipercard': 'far fa-credit-card',
        'unknown': 'far fa-credit-card',
      }

      const brand = cardNumber.value.length && creditCardTypeInfo.value 
        ? creditCardTypeInfo.value.type
        : 'unknown'

      return `${cardBrandToClass[brand]} fa-2x`
    })

    const isCreditCardRequired = computed(() => {
      return paymentMethod.value === 'credit_card'
    })

    const paymentMethodsCount = computed(() => {
      return paymentMethods.value.length
    })

    const cardNumberValidator = (value: string) => {
      if(!isCreditCardRequired.value) {return true}

      if (!creditCardTypeInfo.value) {return false}

      const cardNumberLength = creditCardTypeInfo.value.lengths[0]
      return value.replace(/\D/g, '').length === cardNumberLength
    }

    const cardCvvValidator = (value: string) => {
      if(!isCreditCardRequired.value) {return true}

      if (!creditCardTypeInfo.value) {return false}

      const cvvSize = creditCardTypeInfo.value.code.size
      return value.length === cvvSize
    }

    const cardExpiryValidator = (value: string) => {
      if(!isCreditCardRequired.value) {return true}

      let [month, year] = Array.from(value.split(/[\s/]+/, 2))

      if (!month || !year) { return false }

      if (!/^\d+$/.test(month)) { return false }
      if (!/^\d+$/.test(year)) { return false }
      if (!(1 <= Number(month) && Number(month) <= 12)) { return false }

      if (year.length === 2) {
        if (Number(year) < 70) {
          year = `20${year}`
        } else {
          year = `19${year}`
        }
      }

      if (year.length !== 4) { return false }

      const expiry = new Date(Number(year), Number(month))
      const currentTime = new Date

      // Months start from 0 in JavaScript
      expiry.setMonth(expiry.getMonth() - 1)

      // The cc expires at the end of the month,
      // so we need to make the expiry the first day
      // of the month after
      expiry.setMonth(expiry.getMonth() + 1, 1)

      return expiry > currentTime
    }

    const cardRequiredValidator = (value: string) => {
      return isCreditCardRequired.value ? !!value.trim().length : true
    }

    const rules = computed(() => {
      return {
        cardNumber: {
          cardRequired: helpers.withMessage(t('validations.required'), cardRequiredValidator),
          cardNumber: helpers.withMessage(t('validations.credit_card_number'), cardNumberValidator)
        },
        cardHolderName: {
          cardRequired: helpers.withMessage(t('validations.required'), cardRequiredValidator)
        },
        cardExpirationDate: {
          cardRequired: helpers.withMessage(t('validations.required'), cardRequiredValidator),
          cardExpiry: helpers.withMessage(t('validations.credit_card_expiry'), cardExpiryValidator)
        },
        cardCVV: {
          cardRequired: helpers.withMessage(t('validations.required'), cardRequiredValidator),
          cardCVV: helpers.withMessage(t('validations.credit_card_cvv'), cardCvvValidator)
        },
      }
    })

    const v$ = useVuelidate(rules, {
      cardNumber,
      cardHolderName,
      cardExpirationDate,
      cardCVV
    })

    const cardNumberErrorMessage = computed(() => v$.value.cardNumber.$errors[0]?.$message as string || undefined)
    const cardHolderNameErrorMessage = computed(() => v$.value.cardHolderName.$errors[0]?.$message as string || undefined)
    const cardExpirationDateErrorMessage = computed(() => v$.value.cardExpirationDate.$errors[0]?.$message as string || undefined)
    const cardCVVErrorMessage = computed(() => v$.value.cardCVV.$errors[0]?.$message as string || undefined)

    return {
      paymentMethod,
      cardNumber,
      cardHolderName,
      cardExpirationDate,
      cardCVV,
      cardNumberErrorMessage,
      cardHolderNameErrorMessage,
      cardExpirationDateErrorMessage,
      cardCVVErrorMessage,
      cardNumberMask,
      cvvMask,
      brandClass,
      useMonthlyValue,
      paymentMethodsCount,
      paymentMethods,
      checkoutFormData
    }
  }
})
</script>

<style scoped>
li input[type="radio"]:checked + label {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
</style>
